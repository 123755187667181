@import "bourbon/bourbon";
@import "neat/neat";
@import "base/base";

@import url(http://fonts.googleapis.com/css?family=Lato:300,300italic,400,700,900);

$light-gray: #f3f3f3;
$dark: #262626;

.homepage{
	margin: 3em;
	font-size: 1.15em;
	min-height: 100vh;
	background: $dark;
	font-family: "Lato", sans-serif;
	font-weight: 500;

	header{
		margin-bottom: 1em;
		text-align: center;
	}

	#container{
		@include outer-container;
		$mobile: new-breakpoint(max-width 850px 1);
		@include media($mobile) {
			@include span-columns(1);
			padding: 1em;
		}
		background-color: $light-gray;
		padding: 3em;


		#directions {
			@include span-columns(6);
			@include media($mobile) {
				@include span-columns(1);
			}

			ol{
				list-style-type: decimal;
				list-style-position: inside;
				padding-left: 1em;
				margin-bottom: 2em;
			}
		}

		#form{
			@include span-columns(6);
			@include omega();
			@include media($mobile) {
				@include span-columns(1);

			}

			fieldset{
				width: auto;
				padding: 0;
				margin: 0;
				border: 0;
			}
		}

		.alert {
			padding: 15px;
			margin-bottom: 20px;
			border: 1px solid transparent;
			border-radius: 4px;
		}

		.alert-danger {
			color: #a94442;
			background-color: #f2dede;
			border-color: #ebccd1;
		}
	}
}
